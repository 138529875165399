import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2 Push Jerk + 1 Split Jerk, 5 attempts to a max`}</p>
    <p>{`5×6 Strict Pullups`}</p>
    <p>{`then,`}</p>
    <p>{`8:00 AMRAP of:`}</p>
    <p>{`80-Double Unders`}</p>
    <p>{`40-Calorie Row`}</p>
    <p>{`30-Ring Rows`}</p>
    <p>{`S2OH for max reps in remaining time (135/95)(RX+ 165/115)`}</p>
    <p>{`score=total s2oh reps.`}</p>
    <p><em parentName="p">{`*`}{`compare to 12/1/18`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      